<template>
  <el-upload
    ref="elUpload"
    action="#"
    :auto-upload="false"
    :on-change="change"
    :on-remove="remove"
    :file-list="fileList"
    accept=".bak"
  >
    <dog-button size="mini" type="primary">导入资源</dog-button>
  </el-upload>
</template>

<script>
  export default {
    name: 'uploadBakFile',
    data() {
      return {
        fileList: []
      };
    },
    watch: {
      fileList() {
        this.$emit('input', this.fileList[0]);
      }
    },
    methods: {
      remove(file, fileList) {
        this.fileList = fileList;
      },
      change(file) {
        this.$refs.elUpload.clearFiles();
        if (!['.bak'].some((suffix) => file.name.endsWith(suffix))) {
          this.$message.error('文件格式错误，只能上传bak');
          this.fileList = [];
        } else {
          this.fileList = [file];
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  /deep/ .el-upload-list__item {
    transition: none;
  }
  /deep/ .el-upload-list--text {
    height: 25px;
    margin-top: 10px;
    li {
      margin: 0;
    }
  }
</style>
