<template>
  <dog-dialog
    ref="dogDialog"
    @closed="closed"
    title="导入资源"
    @loading-confirm="confirm"
    confirm-button-text="导 入"
    width="400px"
  >
    <dog-form
      ref="dogForm"
      :form-data="data"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import uploadBakFile from '@/components/uploadBakFile.vue';
  import resourceService from '@/api/service/resource.js';
  export default {
    name: 'import-resource',
    data() {
      return {
        data: {},
        formOptions: [
          {
            type: 'custom',
            formItem: {
              prop: 'file',
              label: '导入文件'
            },
            component: uploadBakFile
          }
        ]
      };
    },
    methods: {
      openFrame() {
        this.$refs.dogDialog.open();
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            if (!this.data.file)
              return Promise.reject(this.$message.error('请选择导入文件'));
            let formData = new FormData();
            formData.append('file', this.data.file.raw);
            return resourceService.importResource(formData).then(() => {
              this.close();
              this.$emit('confirm');
            });
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.data = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
